/* anton-regular - latin */
@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/anton-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/anton-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/anton-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/anton-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/anton-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/anton-v15-latin-regular.svg#Anton') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/rubik-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/rubik-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/rubik-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/rubik-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/rubik-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/rubik-v14-latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
}

.App {
  height: 100vh;
  background-color: rgb(20, 20, 20);
  overflow: scroll;
  text-align: center;
  overflow: hidden;
  background-image: url("./assets/background tile2.png");
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px 0 30px;
  color: white;
  position: relative;
  /* background-color: #35aee2; */
}

.authed-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.header-container {
  /* background-color: #60c657; */
}

.header {
  font-family: 'Anton';
  margin: 0;
  font-size: 70px;
  font-weight: normal;
}

.sub-text {
  font-family: 'Rubik';
  font-weight: normal;
  font-size: 25px;
}

.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.connect-wallet-button {
  color: white;
  background: -webkit-linear-gradient(left, #f59a81, #ff52ff);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mint-button {
  background: -webkit-linear-gradient(left, #ff6682, #ff52ff);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin-left: 10px;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  /* padding-bottom: 45px; */
}

.twitter-logo {
  width: 35px;
  height: 35px;
}

.discord-logo {
  width: 35px;
  height: 35px;
}

.footer-text {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.connected-container input[type='text'] {
  display: inline-block;
  padding: 10px;
  width: 50%;
  height: 60px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 10px;
  margin: 50px auto;
}

.connected-container button {
  height: 50px;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loading {
  font-size: 16px;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}